<template>
<div class="view-home">
  <div class="container questions-wrap pt-5">
    <!-- <div class="question-title">
      <h3 class="text-primary">{{ $t('about.title') }}</h3>
    </div> -->
    <div class="row mt-3">
      <div class="card test-about-card col-11 col-sm-6 mx-auto my-5">
        <div class="row">
          <!-- <div class="col-12 text-center pb-4">
            <img src="/images/tour2.png" alt="" width="140">
          </div> -->
          <div class="col-12">
            <p style="text-align: center;">
<!--              <img :src="quiz[`descr_image_${test_lang}`] ? quiz[`descr_image_${test_lang}`] : quiz.project_id === 1 ? '/images/1000-BALA-logo.png' : '/images/qabilet.png'" alt="">-->
              <img :src="'https://elbasyacademy.kz/uploads/' + quiz[`descr_image_${test_lang}`]" alt="" style="max-width: 100%;">
            </p>
            <h3 class="text-center">{{ titles[currentType] }}</h3>
            <div class="text-center">
              <div
                  v-for="(info, type) in quizData"
                  :key="type"
                  v-if="quiz.questions && currentType === type"
              >
                <div class="btn btn-light">
                  <img src="/images/about-test-button1.svg" alt="">
                  {{ info.questions }} {{ $t('question-about-title-' + info.titleType) }}
                </div>
                <div class="btn btn-light">
                  <img src="/images/about-test-button2.svg" alt="">
                  {{ info.time }}
                </div>
              </div>
            </div>
            <!-- <div class="description">
              {{ quiz['description_' + test_lang ]}}
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="question-content">{{ content[currentType] }}</div>
    </div>
    <div class="row my-5">
      <div class="mx-auto">
        <router-link v-if="quiz.proctoring" to="/identification" tag="a" class="btn btn-default"><i class="el-icon-arrow-left mr-2"></i>{{ $t('about.back-button') }}</router-link>
        <a href="#" @click.prevent="openModal" class="btn btn-primary">{{ $t('about.begin-button') }}</a>
      </div>
    </div>
  </div>
  <b-modal id="confirm-modal" hide-footer hide-header>
    <div id="modal-wrapper">
      <div id="dialog" class="py-3">
        <button @click="$bvModal.hide('confirm-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <h3 class="mb-0">{{ $t('about.begin-modal-title') }}</h3>
        <div class="clearfix pt-3">
          <a @click="beginTest" class="btn btn-primary mx-2">{{ $t('about.begin-modal-button') }}</a>
          <a @click="$bvModal.hide('confirm-modal')" class="btn btn-outline-primary mx-2">{{ $t('about.begin-modal-cancel') }}</a>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>
<script>
export default {
  name: "AboutNew",
  components: {
  },
  methods: {
    pluralizeRus(n, forms) {
      return n % 10 == 1 && n % 100 != 11
          ? forms[0]
          : (n % 10 >= 2 && n % 10 <= 4
          && (n % 100 < 10
              || n % 100 >= 20) ? forms[1] : forms[2]);
    },
    openModal(){
      if (this.quiz.proctoring && !this.identification) {
        this.$router.push({ name: 'identification' })
      }
      this.$bvModal.show('confirm-modal');
    },
    beginTest() {

      const routes = {
        'critical-thinking': '/critical-thinking',
        collaboration: '/collaboration',
        creativity: '/creativity',
        communication: '/communication',
        'self-esteem': '/self-esteem',
        'complex-explanations': '/complex-explanations',
        'creative-generator-test-2': '/creative-generator-test-2',
        'creative-generator': '/composing-image',
        'communication-2': '/incorrect-phrase',
        'collaboration-2': '/thomas-questionnaire',
      };

      const path = routes[this.currentType];
      if (path) {
        this.$router.push({ path });
      }

    },
  },
  mounted() {
    this.currentType = this.$route.params.type

    if (localStorage.getItem('quiz')){
      this.quiz = JSON.parse(localStorage.getItem('quiz'));
      localStorage.setItem('display_results', this.quiz.display_results)

      if (this.quiz.type_id == 3) {
        this.quiz.questions.length = 14
        this.quiz.time = 20
      }
    } else {
      // this.$router.push({ name: 'not-found' })
    }
    if (localStorage.getItem('identification')){
      this.identification = JSON.parse(localStorage.getItem('identification'));
    }
    if (localStorage.getItem('test_lang')){
      this.test_lang = localStorage.getItem('test_lang');
      this.$i18n.locale = this.test_lang;
    }
  },
  data(){
	  return {
	    quiz: {},
      identification: false,
      test_lang: 'ru',
      locale: localStorage.getItem('locale') ?? 'ru',
      currentType: '',
      titles: {
        'critical-thinking': this.$t('critical-thinking.title'),
        'collaboration': 'Коллаборация',
        'creativity': this.$t('creativity.title'),
        'communication': this.$t('communications.title'),
        'self-esteem': this.$t('selfesteem.title'),
        'complex-explanations': this.$t('complex-explanations.title'),
        'creative-generator-test-2': this.$t('creative-generator.subtitle2'),
        'creative-generator': 'Креативность. Методика «Креативный генератор».',
        'communication-2': 'Коммуникация. Методика «Один за всех и все за одного!».',
        'collaboration-2': 'Коллаборация. Методика: Личностный опросник К.Томаса',
      },
      content: {
        'critical-thinking': this.$t('critical-thinking.desc'),
        'collaboration': this.$t('collaboration.desc'),
        'creativity': this.$t('creativity.desc'),
        'communication': this.$t('communications.desc'),
        'self-esteem': this.$t('selfesteem.desc'),
        'complex-explanations': this.$t('complex-explanations.desc'),
        'creative-generator-test-2': this.$t('creative-generator.desc2'),
        'creative-generator': `Методика состоит из 4-х субтестов: субтест №1 - Составление изображения;
                               субтест №2 - Составление слов; субтест №3 - Составление изображений;
                               субтест №4 - Спрятанная форма. Время проведения тестирования - 20 минут.
                               По 5 минут на каждый субтест.`,
        'communication-2': `Методика состоит из 2 субтестов. Каждый субтест содержит от 10 до 12 заданий,
                            которые школьник должен выполнить за определенное время.`,
        'collaboration-2': `Испытуемому предлагаются 30 пар утверждений, в каждой из которых необходимо
                            выбрать присущий испытуемому вариант поведения.`,
      },
      quizData: {
        'critical-thinking': { questions: 18, time: '20 минут', titleType: 1 },
        collaboration: { questions: 14, time: '20 минут', titleType: 1 },
        creativity: { questions: 40, time: '5 минут', titleType: 1 },
        communication: { questions: 3, time: '30 минут', titleType: 2 },
        'self-esteem': { questions: 20, time: '5 минут', titleType: 1 },
        'complex-explanations': { questions: 9, time: '5 минут', titleType: 1 },
        'creative-generator-test-2': { questions: 2, time: '5 минут', titleType: 2 },
        'creative-generator': { questions: 4, time: '20 минут', titleType: 2 },
        'communication-2': { questions: 2, time: '10 минут', titleType: 2 },
        'collaboration-2': { questions: 30, time: '5 минут', titleType: 2 },
      },
    }
  }
}
</script>
<style scoped>
.test-about-card img {
  max-height: 100px;
}
@media screen and (max-width: 678px) {
  .question-content img {
      width: 100%;
  }
}
</style>
